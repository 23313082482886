/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState, useEffect, FunctionComponent } from 'react';
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Cell,
} from 'recharts';
import { Box, Card, Grid, makeStyles } from '@material-ui/core';

export type BarChartProps = {
  responseData: any;
};

export const SpendRateGraph = ({ responseData }: BarChartProps) => {
  const useStyles = makeStyles(() => ({
    creditsActive: {
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      backgroundColor: '#9B9C9D',
    },
    overrun: {
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      backgroundColor: '#CC0000',
    },
    squareActive: {
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      backgroundColor: '#003F5F',
    },
    pendingInvoice: {
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      backgroundColor: '#1059CD',
    },
    squareForeCast: {
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      backgroundColor: '#E6E6E6',
    },
    estimateCurrentFY: {
      height: '3px',
      width: '20px',
      backgroundColor: '#EB0A1E',
    },
    estimatePrevFY: {
      border: 'none',
      borderTop: '4px dashed #EB0A1E',
      height: '5px',
      width: '28px',
    },
    ReferenceLine: {
      height: '3px',
      width: '20px',
      backgroundColor: 'orange',
    },
    legendSpacing: {
      display: 'flex',
      alignItems: 'center',
    },
    legendSpacingline: {
      paddingTop: '9px',
      display: 'flex',
      alignItems: 'center',
    },
    spacingColor: {
      marginLeft: '10px',
      marginRight: '25px',
    },
    graph: {
      paddingLeft: '20px',
      marginBottom: '-50px',
    },
    disclaimer: {
      margin: '30px',
      paddingLeft: '190px',
      fontStyle: 'italic',
    },
    yAxisLabel: {
      textAlign: 'center',
      position: 'relative',
      top: '-215px',
      left: '-540px',
      transform: 'rotateZ(-90deg)',
      '@media (min-width: 1400px)': {
        left: '-591px',
      },
    },
    customTooltip: {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      padding: '4px',
      background: '#ffffff',
    },
    tooltipHeader: {
      margin: '0',
      marginBottom: '4px',
    },
    tooltipLabel: {
      margin: '0',
      color: 'rgb(0, 63, 95)',
    },
  }));

  const classes = useStyles();
  const [result, setResult] = useState(Array);
  const fiscalmonthArray = [
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar',
  ];
  const montharray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let dataLength = 0;
  if (responseData.accounts_total) {
    dataLength = Object.keys(responseData.accounts_total).length;
  }
  const date = new Date();
  const currentMonth = date.getMonth();
  const getCurrentFiscalMonth = () => {
    let fiscalmonth = 0;
    if (currentMonth >= 0 && currentMonth < 3) {
      fiscalmonth = currentMonth + 9;
    } else if (currentMonth > 2 && currentMonth < 12) {
      fiscalmonth = currentMonth - 3;
    }
    return fiscalmonth;
  };
  const CurrentFullYear = date.getFullYear();
  // @ts-ignore
  let currentYear = 0;
  if (date.getMonth() + 1 <= 3) {
    currentYear = CurrentFullYear - 1;
  } else {
    currentYear = CurrentFullYear;
  }
  const data:
    | ((prevState: never[]) => never[])
    | { month: any; monthlyCost: any }[] = [];
  const dataActual:
    | ((prevState: never[]) => never[])
    | { month: any; monthlyCost: any }[] = [];

  const assignGraphData = () => {
    for (const item in responseData.accounts_total) {
      if (item !== null) {
        const itemArray = item.split('-');
        const dataItem = {
          month: '',
          monthlyCost: 0,
        };
        dataItem.month = `${
          montharray[parseInt(itemArray[1], 10) - 1]
        }-${itemArray[0].substring(2)}`;
        dataItem.monthlyCost = responseData.accounts_total[item];
        data.push(dataItem);
        dataActual.push(dataItem);
      }
    }
    const lastKey = Object.keys(responseData.accounts_total).pop();
    const resultLength = Object.keys(responseData.accounts_total).length;
    if (resultLength === 7) {
      for (let i = 0; i < 5; i++) {
        if (lastKey !== undefined) {
          const itemArray = lastKey.split('-');
          const dataItem = {
            month: '',
            monthlyCost: 0,
          };
          if (parseInt(itemArray[1], 10) + i >= 12) {
            dataItem.month = `${
              montharray[parseInt(itemArray[1], 10) + i - 12]
            }-${parseInt(itemArray[0].substring(2)) + 1}`;
          } else {
            dataItem.month = `${
              montharray[parseInt(itemArray[1], 10) + i]
            }-${itemArray[0].substring(2)}`;
          }
          dataItem.monthlyCost = 0;
          data.push(dataItem);
          dataActual.push(dataItem);
        }
      }
    } else if (resultLength === 6) {
      for (let i = 0; i < 6; i++) {
        if (lastKey !== undefined) {
          const itemArray = lastKey.split('-');
          const dataItem = {
            month: '',
            monthlyCost: 0,
          };
          if (parseInt(itemArray[1], 10) + i >= 12) {
            dataItem.month = `${
              montharray[parseInt(itemArray[1], 10) + i - 12]
            }-${parseInt(itemArray[0].substring(2)) + 1}`;
          } else {
            dataItem.month = `${
              montharray[parseInt(itemArray[1], 10) + i]
            }-${itemArray[0].substring(2)}`;
          }
          dataItem.monthlyCost = 0;
          data.push(dataItem);
          dataActual.push(dataItem);
        }
      }
    }
    const currentFiscalMonth = getCurrentFiscalMonth();
    data.map((item, index) => {
      if (item.month.match(fiscalmonthArray[currentFiscalMonth])) {
        if (item.monthlyCost === 0) {
          try {
            if (data[index - 1].monthlyCost === 0) {
              data[index - 1].monthlyCost = responseData.forecasted_spend_1;
              data[index].monthlyCost = responseData.forecasted_spend_2;
              data[index + 1].monthlyCost = responseData.forecasted_spend_3;
            } else {
              data[index].monthlyCost = responseData.forecasted_spend_1;
              data[index + 1].monthlyCost = responseData.forecasted_spend_2;
              data[index + 2].monthlyCost = responseData.forecasted_spend_3;
            }
          } catch (e) {
            console.log('error', e);
          }
        }
      }
    });

    data.map((item: any) => {
      if (Math.sign(item.monthlyCost as number) === -1) {
        item.monthlyCost = Math.abs(item.monthlyCost as number);
        item['sign'] = 'negative';
      } else {
        item['sign'] = 'positive';
      }
      return item;
    });
    console.log('data for spend Graph ', data);
    setResult(data);
  };

  useEffect(() => {
    if (responseData.accounts_total && result.length === 0) {
      assignGraphData();
    }
  });

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const currencyFormatterWithMaxFraction = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    if (payload.value) {
      if (payload.value.includes('-')) {
        const month = payload.value.split('-');
        if (month[0] === montharray[date.getMonth()]) {
          return (
            <g transform={`translate(${x},${y})`}>
              <text
                underlineThickness={2}
                x={0}
                y={0}
                dy={16}
                dx={20}
                textAnchor="end"
                fill="red"
                fontWeight="bold"
              >
                {payload.value}
              </text>
            </g>
          );
        }
      }
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          underlineThickness={2}
          x={0}
          y={0}
          dy={16}
          dx={20}
          textAnchor="end"
          fill="black"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length > 0 && payload[0].value !== 0) {
      return (
        <div className={classes.customTooltip}>
          <p className={classes.tooltipHeader}>{`${label}`}</p>
          <p
            className={classes.tooltipLabel}
          >{`Monthly Cost : ${currencyFormatterWithMaxFraction.format(
            payload[0].value,
          )}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {result && dataActual && (
        <Card>
          <Box position="relative" data-testid="bar-chart-wrapper">
            <Grid container style={{ marginTop: '1%' }}>
              <Grid item xs={12}>
                <div
                  style={{
                    float: 'left',
                    transform: 'rotate(-90deg)',
                    position: 'relative',
                    right: '19px',
                    top: '48%',
                  }}
                >
                  Cost (USD)
                </div>
                <ResponsiveContainer
                  height={300}
                  width={1200}
                  className={classes.graph}
                >
                  <ComposedChart
                    width={1200}
                    data={result}
                    barSize={45}
                    data-testid="bar-chart"
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 30,
                    }}
                  >
                    <CartesianGrid vertical={false} stroke="#e4e2e2" />
                    <XAxis
                      dataKey="month"
                      tick={<CustomizedAxisTick />}
                      tickLine={false}
                      interval={0}
                      height={100}
                    />
                    <YAxis
                      tickFormatter={currencyFormatter.format}
                      // label={}
                    />

                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                      name="Monthly Cost"
                      dataKey="monthlyCost"
                      stackId="a"
                      fill="#003F5F"
                    >
                      {result.map((item: any, index: any) => {
                        if (index > dataLength - 1) {
                          if (
                            index === dataLength &&
                            montharray[index - 3] !== montharray[currentMonth]
                          ) {
                            return <Cell fill="#1059CD" />;
                          }
                          return <Cell fill="#E6E6E6" />;
                        }
                        if (item.sign == 'negative') {
                          return <Cell key={index} fill="#9B9C9D" />;
                        }
                        if (
                          item.monthlyCost >
                          responseData.monthly_estimated_spend
                        ) {
                          return <Cell key={index} fill="#CC0000" />;
                        }

                        return <Cell fill="#003F5F" />;
                      })}
                    </Bar>
                    <ReferenceLine
                      y={responseData.monthly_estimated_spend}
                      stroke="#9B9C9D"
                      strokeWidth="2"
                      opacity={0.5}
                      isFront={false}
                      ifOverflow="extendDomain"
                      label={{
                        position: 'insideBottomRight',
                        value: `${currencyFormatterWithMaxFraction.format(
                          responseData.monthly_estimated_spend,
                        )}`,
                        fill: 'black',
                        fontWeight: 'bold',
                      }}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>

            <Grid container style={{ marginLeft: '2%' }}>
              <Grid item>
                <div className={classes.legendSpacing}>
                  <p className={classes.creditsActive}> </p>
                  <span className={classes.spacingColor}>
                    Quarterly credits applied to monthly chargeback
                  </span>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.legendSpacing}>
                  <p className={classes.squareActive}> </p>
                  <span className={classes.spacingColor}>
                    Actual Monthly Cost
                  </span>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.legendSpacing}>
                  <p className={classes.pendingInvoice}> </p>
                  <span className={classes.spacingColor}>Pending Invoice</span>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.legendSpacing}>
                  <p className={classes.squareForeCast}> </p>
                  <span className={classes.spacingColor}>
                    Forecast Monthly Cost
                  </span>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.legendSpacing}>
                  <p className={classes.overrun}> </p>
                  <span className={classes.spacingColor}>Overrun</span>
                </div>
              </Grid>
            </Grid>
            <div style={{fontStyle:'italic'}}>Note: Spend rate graph will update to latest chargebacks on the 15th of each month</div>
          </Box>
        </Card>
      )}
    </>
  );
};
