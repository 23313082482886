import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  Typography,
} from '@material-ui/core';
import { PaginationAutocomplete } from './PaginateAutocomplete';
import React, { FC, useMemo, useState } from 'react';
import { useApi, githubAuthApiRef } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { appCatalogApiRef } from '../../api';
import { FaqLink, LoadingButton } from './HasSubcomponentsCard';
import { CustomAlertMessage } from './CustomAlertDisplay';

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: '600px',
  },
  noButton: {
    color: '#000000',
    boxShadow: '0 0 0 2px #000000 inset',
    marginRight: '16px',
    background: 'white',
  },
}));

type LinkRepoDialogProps = {
  isOpen: 'repo' | 'cloud' | null;
  triggerAlert: (alert: CustomAlertMessage) => void;
  handleClose: () => void;
};

export const LinkRepoDialog: FC<LinkRepoDialogProps> = props => {
  const { isOpen, triggerAlert, handleClose } = props;
  const classes = useStyles();
  const { entity } = useEntity();
  const [selectedRepos, setSelectedRepos] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const appCatalogApi = useApi(appCatalogApiRef);
  const githubAuthApi = useApi(githubAuthApiRef);

  const isAssociateDisabled = useMemo(() => selectedRepos.length === 0, [
    selectedRepos.length,
  ]);

  const onClose = () => {
    setSelectedRepos([]);
    handleClose();
  };

  const onDialogClose: DialogProps['onClose'] = (_event, reason) => {
    if (reason === 'backdropClick' && isLoading) return;
    onClose();
  };

  const handleAssociate = async () => {
    setIsLoading(true);
    const listFormat = new Intl.ListFormat('en');
    const toyotaSysId = entity.metadata?.toyotaSysId;
    const appName = entity.metadata?.appName;

    const repo = selectedRepos.map(({ id, repoName }) => ({
      id,
      name: repoName,
    }));
    const githubToken = await githubAuthApi.getAccessToken([
      'repo',
      'workflow',
    ]);
    const result = await appCatalogApi.linkRepo(repo, toyotaSysId, githubToken);

    const successRepos = result.filter(
      ({ status }: any) => status === 'success',
    );
    const failureRepos = result.filter(
      ({ status }: any) => status === 'failure',
    );

    const failedTeamList = [
      ...new Set<string>(
        failureRepos.map(({ name }: any) =>
          `${name.split('-')[0]}-admin`.toUpperCase(),
        ),
      ),
    ];
    const failedTeamFormatted = listFormat.format(failedTeamList);

    const noOfSuccessRepos = successRepos.length;
    const noOfFailureRepos = failureRepos.length;

    const allSuccess = noOfSuccessRepos === result.length;
    const allFailure = noOfFailureRepos === result.length;
    const mixedStatus = !allSuccess && !allFailure;

    if (allSuccess) {
      triggerAlert({
        message: `${noOfSuccessRepos} Repo(s) linked to ${appName} successfully. Changes should reflect in the next 3-5 minutes.`,
        severity: 'success',
        display: 'transient',
      });
    } else if (allFailure) {
      triggerAlert({
        message: (
          <React.Fragment>
            Linking repo(s) to application failed due to insufficient
            permissions. Please ensure you are a part of {failedTeamFormatted}.
            <br />
            To request access, follow instructions in this <FaqLink />.
          </React.Fragment>
        ),
        severity: 'error',
        display: 'transient',
        autoHideDuration: 10000,
      });
    } else if (mixedStatus) {
      triggerAlert({
        message: (
          <React.Fragment>
            {noOfSuccessRepos} Repo(s) linked successfully. Changes should
            reflect in the next 3-5 minutes. <br />
            Rest could have failed due to insufficient permissions. Please
            ensure you are a part of {failedTeamFormatted}.<br /> To request
            access, follow instructions in this <FaqLink />.
          </React.Fragment>
        ),
        severity: 'warning',
        display: 'transient',
        autoHideDuration: 10000,
      });
    }
    setIsLoading(false);
    onClose();
  };
  return (
    <Dialog
      open={isOpen === 'repo'}
      onClose={onDialogClose}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle>Search, Select And Associate Repos</DialogTitle>
      <DialogContent>
        <PaginationAutocomplete
          selectedRepos={selectedRepos}
          setSelectedRepos={setSelectedRepos}
        />
        <span>*Archived repos will not be listed.</span>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleAssociate}
          color="primary"
          disabled={isAssociateDisabled || isLoading}
          loading={isLoading}
        >
          Associate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

type UnlinkRepoDialogProps = {
  isOpen: boolean;
  triggerAlert: (alert: CustomAlertMessage) => void;
  handleClose: () => void;
  repoDeleteData: any;
};

export const UnlinkRepoDialog: FC<UnlinkRepoDialogProps> = props => {
  const { isOpen, handleClose, triggerAlert, repoDeleteData } = props;
  const classes = useStyles();
  const { entity } = useEntity();
  const [isLoading, setIsLoading] = useState(false);
  const appCatalogApi = useApi(appCatalogApiRef);
  const githubAuthApi = useApi(githubAuthApiRef);

  const onClose = () => {
    handleClose();
  };

  const onDialogClose: DialogProps['onClose'] = (_event, reason) => {
    if (reason === 'backdropClick' && isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const appName = entity.metadata?.appName;
    const githubToken = await githubAuthApi.getAccessToken([
      'repo',
      'workflow',
    ]);

    const result = await appCatalogApi.unLinkRepo(
      repoDeleteData.repoName,
      repoDeleteData.appId,
      githubToken,
    );

    const repoName = result?.name ?? '';
    const teamName = `${repoName.split('-')[0]}-admin`.toUpperCase();

    if (result.status === 'success') {
      triggerAlert({
        message: `${appName} has been unlinked from the ${repoName} successfully. Changes should reflect in the next 3-5 minutes.`,
        severity: 'success',
        display: 'transient',
      });
    } else {
      triggerAlert({
        message: (
          <React.Fragment>
            Unlinking failed due to insufficient permissions. Please ensure you
            are a part of {teamName}.<br /> To request access, follow
            instructions in this <FaqLink />.
          </React.Fragment>
        ),
        severity: 'error',
        display: 'transient',
        autoHideDuration: 10000,
      });
    }
    setIsLoading(false);
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <DialogContent>
        <Typography gutterBottom>
          Please confirm that you intend to remove association from app.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          className={classes.noButton}
        >
          No
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleDelete}
          color="primary"
          loading={isLoading}
          disabled={isLoading}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
